import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../../services/api';
import { ErrorBanner, SuccessBanner } from "../../../components/banner/banner";

const initialState = {
  totalCount: 0,
  subscriptions: [],
  subscriptionInfo: {},
  addSubscriptionProcessCompleted: null,
  updateSubscriptionProcessCompleted: null,
  deleteSubscriptionProcessCompleted: null,
  fetchListing: null
}

export const subscriptionsList = createAsyncThunk(
  'subscription/subscriptionsList',
  async (data) => {
    try {
      const response = await API.post(`/admin/subscriptions`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionAddNew = createAsyncThunk(
  'subscription/subscriptionAddNew',
  async (data) => {
    try {
      const response = await API.post(`/admin/add-subscription/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionEdit = createAsyncThunk(
  'subscription/subscriptionEdit',
  async (id) => {
    try {
      const response = await API.get(`/admin/edit-subscription/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionUpdate = createAsyncThunk(
  'subscription/subscriptionUpdate',
  async (data) => {
    try {
      const response = await API.put(`/admin/update-subscription/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionDelete = createAsyncThunk(
  'subscription/subscriptionDelete',
  async (id) => {
    try {
      const response = await API.delete(`/admin/delete-subscription/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.addSubscriptionProcessCompleted = null
      state.updateSubscriptionProcessCompleted = null
      state.deleteSubscriptionProcessCompleted = null
      state.fetchListing = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(subscriptionsList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.subscriptions = action.payload.data === null ? [] : action.payload.data.results
        state.totalCount = action.payload.data === null ? [] : action.payload.data.results.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(subscriptionEdit.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.subscriptionInfo = action.payload.data === null ? [] : action.payload.data.results
      } else if (action.payload.success === false && parseInt(action.payload.status) === 404) {
        window.location = '/subscriptions'
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(subscriptionDelete.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.deleteSubscriptionProcessCompleted = true
        SuccessBanner(action.payload.message)
      } else {
        state.deleteSubscriptionProcessCompleted = false
        state.message = action.payload.message
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(subscriptionAddNew.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.addSubscriptionProcessCompleted = true
      } else {
        state.addSubscriptionProcessCompleted = false
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(subscriptionUpdate.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.updateSubscriptionProcessCompleted = true
      } else {
        state.updateSubscriptionProcessCompleted = false
        ErrorBanner(action.payload.message)
      }
    });
  }
})

export const { resetAllState } = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer