import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Col, FormGroup, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitContactForm, resetAllState } from "../header/HeaderSlice";

const TalkToExpert = (props) => {
  const dispatch = useDispatch();
  const { message, submitProcessCompleted, errorsFromApiResponse } =
    useSelector((state) => state.header);
  const [googleCaptchaVerified, setGoogleCaptchaVerified] = useState(false);
  const googleReCaptchaOnChange = (value) => {
    setGoogleCaptchaVerified(value !== null ? true : false);
  };

  if (
    window.location.pathname === "/registration-success" &&
    (localStorage.getItem("user") !== null ||
      localStorage.getItem("user") === undefined)
  ) {
    window.location = "/";
  }

  const [PersonalLoader, setPersonalLoader] = useState(false);
  const TalkToExpert_Formik = useFormik({
    initialValues: {
      salutation: "",
      business: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      message: "",
      checkbox: false,
    },
    validationSchema: Yup.object({
      salutation: Yup.string()
        .required("Das Feld Anrede ist erforderlich.")
        .matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      business: Yup.string()
        .required("Das Feld Firma ist erforderlich.")
        .min(3),
      first_name: Yup.string()
        .required("Das Feld Vorname ist erforderlich.")
        .matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      last_name: Yup.string()
        .required("Das Feld Nachname ist erforderlich.")
        .matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      email: Yup.string()
        .email("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
        .required("Das Feld E-Mail ist erforderlich."),
      phone_number: Yup.number()
        .nullable()
        .test(
          "maxDigits",
          "Ungütlige Telefonnummer.",
          (number) =>
            (String(number).length >= 10 && String(number).length <= 12) ||
            number === undefined ||
            number === null
        ),
      message: Yup.string().required("Das Feld Anfrage ist erforderlich."),
      checkbox: Yup.boolean().oneOf(
        [true],
        "Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren."
      ),
    }),
    onSubmit: async (values) => {
      setPersonalLoader(true);
      dispatch(SubmitContactForm(values));
    },
  });

  useEffect(() => {
    if (submitProcessCompleted) {
      dispatch(resetAllState());
      setPersonalLoader(false);
      TalkToExpert_Formik.resetForm();
      props.handleHide(true);
    }
    if (errorsFromApiResponse) {
      setPersonalLoader(false);
      TalkToExpert_Formik.setErrors({
        salutation: errorsFromApiResponse.salutation,
        business: errorsFromApiResponse.business,
        first_name: errorsFromApiResponse.first_name,
        last_name: errorsFromApiResponse.last_name,
        email: errorsFromApiResponse.email,
        message: errorsFromApiResponse.message,
        phone_number: errorsFromApiResponse.phone_number,
        checkbox: errorsFromApiResponse.checkbox,
      });
    }
  }, [
    dispatch,
    props,
    TalkToExpert_Formik,
    errorsFromApiResponse,
    submitProcessCompleted,
  ]);

  return (
    <>
      {/* Conatct Modal */}
      <Modal
        centered
        show={props.handleShow}
        onHide={props.handleHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="talk-to-experts"
      >
        <Modal.Header
          onClick={() => TalkToExpert_Formik.resetForm()}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <form onSubmit={TalkToExpert_Formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="email">Anrede</label>
                  <input
                    type="text"
                    name="salutation"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.salutation}
                  />
                  {TalkToExpert_Formik.errors.salutation &&
                  TalkToExpert_Formik.touched.salutation ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.salutation}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="email">Firma</label>
                  <input
                    type="text"
                    name="business"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.business}
                  />
                  {TalkToExpert_Formik.errors.business &&
                  TalkToExpert_Formik.touched.business ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.business}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="email">Vorname</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.first_name}
                  />
                  {TalkToExpert_Formik.errors.first_name &&
                  TalkToExpert_Formik.touched.first_name ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.first_name}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="email">Nachname</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.last_name}
                  />
                  {TalkToExpert_Formik.errors.last_name &&
                  TalkToExpert_Formik.touched.last_name ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.last_name}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="email">E-Mail</label>
                  <input
                    type="text"
                    name="email"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.email}
                  />
                  {TalkToExpert_Formik.errors.email &&
                  TalkToExpert_Formik.touched.email ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.email}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <div className="d-flex">
                    <label htmlFor="phone_number">
                      Telefonnummer{" "}
                      <small className="optional-text">(optional)</small>
                    </label>
                  </div>

                  <input
                    type="number"
                    name="phone_number"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.phone_number}
                  />
                  {TalkToExpert_Formik.errors.phone_number &&
                  TalkToExpert_Formik.touched.phone_number ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.phone_number}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  <label htmlFor="message">Anfrage</label>
                  <textarea
                    name="message"
                    placeholder=" "
                    onChange={TalkToExpert_Formik.handleChange}
                    onBlur={TalkToExpert_Formik.handleBlur}
                    value={TalkToExpert_Formik.values.message}
                  />
                  {TalkToExpert_Formik.errors.message &&
                  TalkToExpert_Formik.touched.message ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.message}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="custom-form-control">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                  onChange={googleReCaptchaOnChange}
                />
                {message ? (
                  <span className="error-massage">{message}</span>
                ) : (
                  <span className="error-massage">&nbsp;</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup
                  className="custom-form-control"
                  controlId="formBasicEmail"
                >
                  {/* <label className="custom-checkbox mb-0"></label> */}
                  <label class="custom-checkbox mb-0">
                    <input
                      type="checkbox"
                      onChange={TalkToExpert_Formik.handleChange}
                      name="checkbox"
                    />
                    <span class="checkmark"></span>
                    <p>
                      Ich habe die{" "}
                      <Link
                        target="_blank"
                        to={"/legal-notice"}
                        className="custom-link-info ms-1 me-1"
                      >
                        Informationen zum Datenschutz
                      </Link>{" "}
                      verstanden und zur Kenntnis genommen.
                    </p>
                  </label>

                  <div className="clearfix"></div>
                  {TalkToExpert_Formik.errors.checkbox ? (
                    <span className="error-massage">
                      {TalkToExpert_Formik.errors.checkbox}
                    </span>
                  ) : (
                    <span className="error-massage">&nbsp;</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  type="submit"
                  disabled={
                    !(
                      TalkToExpert_Formik.isValid &&
                      TalkToExpert_Formik.dirty &&
                      googleCaptchaVerified
                    )
                  }
                  className="btn btn-primary btn btn-primary"
                >
                  Anfrage absenden&nbsp;
                  {PersonalLoader ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : null}
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TalkToExpert;
